<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg4 pr-6 pl-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedStartDateFormatted"
                    label="Từ ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.startDate" no-title @input="menuStart = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg4 pr-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedEndDateFormatted"
                    label="Đến ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.endDate" no-title @input="menuEnd = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg4 pr-6>
            <v-btn @click="filter_data" color="#a77800" outlined>Xem</v-btn>
        </v-flex>
        <v-flex lg12>
            <template v-for="(item, i) in items">
                <div v-bind:key="i">
                <h4>Tháng: {{item.month.replace(/(\d{4})(\d{2})/, "$2-$1")}}</h4>
                <v-card class="mb-8">
                    <v-data-table fixed-header height="750px"   hide-default-footer :headers="headers" :items="item.rows" :server-items-length="item.rows.length" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ getStaffName(item.CustomerServiceID) }}</td>
                                <td class="text-end">{{ item.NumberOfOrder | currency}}</td>
                                <td class="text-end">{{ item.TotalFeeBuyAmount | currency}}</td>
                                <td class="text-end">{{ item.AvgFeeBuyAmount | currency('%',2)}}</td>
                                <td class="text-end" v-if="!adminRole">{{ item.ManualCom | currency('',2)}}</td>
                                <td class="text-end red--text" v-if="adminRole">
                                    <v-edit-dialog :return-value.sync="item.ManualCom" large lazy persistent @save="saveItem(item)">
                                    <div>{{ item.ManualCom | currency('',2)}}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.ManualCom" type="number" step=".1" label="Tỷ lệ thưởng" single-line autofocus></v-text-field>
                                    </template>
                                    </v-edit-dialog>
                                </td>
                                <td class="text-end">{{ item.Reward | currency}}</td>
                            </tr>
                        </template>
                        <template v-slot:body.append>
                            <tr>
                                <td class="font-weight-bold">TỔNG</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].NumberOfOrder | currency}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].TotalFeeBuyAmount | currency}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].AvgFeeBuyAmount| currency('%',2)}}</td>
                                <td></td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].Reward | currency}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
                </div>
            </template>
            
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import { request_list } from '@/commons/hpod';
import Vue from 'vue';
import _ from "lodash";
import moment from "moment"
export default {
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            headers: [
                {
                    text: "Nhân viên",
                    value: "StaffName",
                    filterable: true,
                    quickSearch: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Tổng giao dịch",
                    value: "NumberOfOrder",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Phí mua hàng",
                    value: "TotalFeeBuyAmount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Trung bình",
                    value: "AvgFeeBuyAmount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Tỷ lệ thưởng",
                    value: "ManualCom",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Doanh thu được hưởng",
                    value: "Reward",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                }
            ],
            viewName: "thong-ke-phi-mua-hang",
            dateRange: {
                startDate: moment.utc(Date.now()).startOf('month').toISOString().substr(0, 10),
                endDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
            },
            menuStart: false,
            menuEnd: false,
        };
    },
    computed: {
        ...mapState({
            items: state => state.report.orderFeeBuy.data,
            loading: state => state.report.loading,
            staffs: state =>
                _.filter(state.commons.all_users, function(o) {
                return ((o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase) || ((o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService);
            }),
        }),
        computedStartDateFormatted () {
            return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
        },
        computedEndDateFormatted () {
            return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        dic_summary() {
            let dic = {};
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                dic[item.month] = {
                    'NumberOfOrder': _.sumBy(item.rows, 'NumberOfOrder'),
                    'TotalFeeBuyAmount': _.sumBy(item.rows, 'TotalFeeBuyAmount'),
                    'AvgFeeBuyAmount': 0,
                    'Reward': _.sumBy(item.rows, 'Reward')
                }
                dic[item.month].AvgFeeBuyAmount = dic[item.month].TotalFeeBuyAmount/dic[item.month].NumberOfOrder*100
            }
            return dic;
        }
    },
    methods: {
        filter_data: function () {
            var param = {
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
            };
            this.$store.dispatch("report/getOrderFeeBuy", param);
        },
        saveItem(item) {
            item.Reward = Math.floor(item.TotalFeeBuyAmount*item.ManualCom/100);
            this.$store.dispatch("report/setOrderFeeBuy", {
                data: item,
                id: item.ID
            });
        },
        getStaffName(staffId) {
            const staff = _.find(this.staffs, {
                ID: staffId
            });
            return staff ? staff.FullName : "";
        },
    },
    mounted() {
        this.$store.dispatch("commons/getAllUsers");
        this.filter_data();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>